.customButton {
    border: 1px solid var(--lib-border-default);
}

.customButton:disabled {
    background-color: var(--lib-surface-main) !important;
}

.customButton:active {
    background-color: var(--lib-surface-main) !important;
}

.mainImg {
    font-size: 52px;
    display: block;
    text-align: center;
    margin-bottom: 20px;
}
