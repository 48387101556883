.root {
    width: 100%;
    padding: 6px var(--default-indent);
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--lib-decorative-a);
    z-index: 100;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: var(--lib-decorative-c);
    transition: 0.3s;
}

.rounded {
    border-radius: 8px;
    width: 100%;
    margin: 0 auto 24px;
}

.timerBox {
    min-width: 48px;
}

.timerEnded {
    background: #8696a6;
}

.svgWrap {
    margin-right: 8px;
}

.checkoutContainer {
    position: absolute;
    top: 0;
    left: 0;
    padding: 8px var(--default-indent);
    font-size: 12px;
    line-height: 16px;
}

.smallTimer {
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    min-width: 0;
}
