.outlinedButton {
    background-color: var(--lib-surface-main) !important;
    border: 1px solid var(--lib-border-default);
}

.outlinedButton:hover {
    background-color: var(--lib-surface-hovered) !important;
}

.outlinedButton:active {
    background-color: var(--lib-surface-hovered) !important;
}

.outlinedButton:disabled {
    cursor: not-allowed;
    background-color: var(--lib-secondary-disabled) !important;
}

.copyBtn {
    padding: 6px 16px !important;
    min-height: 44px !important;
    border-radius: 8px !important;
    max-width: inherit !important;
}
