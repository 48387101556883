.popUpWrap {
    height: 100vh;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
}

.errorPopUpBlock {
    max-width: 432px;
    padding: 0 24px;
}

.buttonBox {
    max-width: 400px;
    margin: 0 auto;
}

.mainButton {
    background-color: var(--primary-default) !important;
}
